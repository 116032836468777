import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import * as ALL_LANGS from "../src/locale"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      it_IT: ALL_LANGS.it_IT,
      en_US: ALL_LANGS.en_US,
    },
    lng: process.env.NEXT_PUBLIC_LANG,
    fallbackLng: "it_IT",

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
