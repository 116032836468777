import "../../styles/globals.css"
import type { AppProps } from "next/app"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import Error from "next/error"
import { SWRConfig } from "swr"
import { MainLayout } from "../components/layout"
import { UserProvider } from "@auth0/nextjs-auth0"
import "../../lib/i18n"
import { UserFavoritesProvider } from "../hooks/use-user-favorites"
import Spinner from "../components/feedback/spinner"
import moment from "moment"
import "moment/locale/it"

moment.locale("it")

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const [loadingPage, setLoadingPage] = useState(false)

  const handleLoadingStart = () => {
    setLoadingPage(true)
  }

  const handleLoadingStop = () => {
    setLoadingPage(false)
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      window.document.addEventListener("contextmenu", evt =>
        evt.preventDefault()
      )
    }

    router.events.on("routeChangeStart", handleLoadingStart)
    router.events.on("routeChangeComplete", handleLoadingStop)
    router.events.on("routeChangeError", handleLoadingStop)

    return () => {
      router.events.off("routeChangeStart", handleLoadingStart)
      router.events.off("routeChangeComplete", handleLoadingStop)
      router.events.off("routeChangeError", handleLoadingStop)
    }
  }, [])

  if (pageProps.error) {
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    )
  }

  return (
    <UserProvider>
      <UserFavoritesProvider>
        <SWRConfig value={{ revalidateOnFocus: false }}>
          <MainLayout>
            {loadingPage && (
              <div className="fixed bottom-4 right-4">
                <Spinner />
              </div>
            )}
            <Component {...pageProps} />
          </MainLayout>
        </SWRConfig>
      </UserFavoritesProvider>
    </UserProvider>
  )
}

export default MyApp
