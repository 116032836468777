import moment from "moment"
import i18n from "../../lib/i18n"
import { AxiosError, AxiosResponse } from "axios"
import { UserProfile } from "@auth0/nextjs-auth0/src/frontend/use-user"
import { IArtwork, IAuthor, IUserProfile } from "../interfaces/models"
import { SeoProps } from "../components/seo/seo"

export const devLog =
  process.env.NODE_ENV !== "production" ? console.log : (): void => {}

export const formatDate = (
  date: Date | string | null | undefined,
  format = "lll"
) => {
  if (!date) {
    return i18n.t("label:invalidDate")
  }

  const momentDate = moment(date)

  const diff = -momentDate.diff(moment(), "day")

  if (diff < 2) {
    return momentDate.fromNow()
  }

  if (!momentDate.isValid()) {
    return i18n.t("label:invalidDate")
  }

  return momentDate.format(format)
}

export const formatPrice = (
  price: number | undefined | null,
  currency = "€"
) => {
  if (!price && price !== 0) {
    return "--"
  }
  return `${price.toFixed(2).replace(".", ",")} ${currency}`
}

export const createError = (
  message: string,
  statusCode: number,
  description: string | null = null
) => {
  return {
    message,
    statusCode,
    description,
  }
}

export const uiHandleError = (
  error: Error | AxiosError<any> | AxiosResponse<any>
) => {
  let _message = "generic"
  if ("response" in error) {
    _message =
      error.response?.data?.desription ||
      error.response?.data?.message ||
      error.response?.data?.error?.message ||
      _message
  } else if ("request" in error) {
    // case request has no response
    _message = "noResponse"
  }

  _message = i18n.t(`error:${_message}`)

  window.alert(_message)
}

interface UiHandleSuccessOptions {
  message?: string
  action?: () => void
}

export const uiHandleSuccess = (options: UiHandleSuccessOptions) => {
  const message = options.message || i18n.t("message:operationComplete")

  window.alert(message)
  options.action && options.action()
}

export const formatFileSize = (
  bytes: number | null | undefined,
  si = false,
  dp = 1
): string => {
  if (!bytes) {
    return "-"
  }

  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + " " + units[u]
}

export const getDisplayName = (
  user: UserProfile | IUserProfile | null | undefined
): string => {
  if (!user) {
    return ""
  }

  let displayName = user.email

  if ("nickname" in user) {
    displayName = user.nickname
  }

  if ("firstname" in user) {
    displayName = `${user.firstname} ${user.lastname}`
  }

  return displayName || ""
}

export const getUserAvatar = (
  user: UserProfile | IUserProfile | null | undefined,
  type = "bottts"
): string => {
  return `https://avatars.dicebear.com/api/${type}/${encodeURIComponent(
    getDisplayName(user)
  )}.svg`
}

export const getAuthorAvatar = (author: IAuthor, type = "initials"): string => {
  return `https://avatars.dicebear.com/api/${type}/${encodeURIComponent(
    getAuthorDisplayName(author) || ""
  )}.svg`
}

export const __DEV__ = process.env.NODE_ENV !== "production"

export const getImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute("crossOrigin", "")
    img.onload = () => {
      resolve(img)
    }
    img.onerror = e => {
      reject(e)
    }
    img.src = url
  })
}

export const getColorsFromBuffer = (buffer: Buffer) => {}

export const getQueryParamAsString = (
  param: string | string[] | undefined
): string | undefined => {
  return Array.isArray(param) ? param[0] : param
}

export const getQueryParamAsArray = (
  param: string | string[] | undefined
): string[] => {
  if (!param) {
    return []
  }

  return Array.isArray(param) ? param : [param]
}

export const getAuthorDisplayName = (author?: IAuthor | null): string => {
  if (!author) {
    return ""
  }
  if (author.firstname && author.lastname) {
    return `${author.firstname} ${author.lastname}`
  }

  return author.email || ""
}

export const mergeClasses = (classes: string[]) => {
  return classes.filter(Boolean).join(" ")
}

export const getArtworkPreviewUrl = (artwork: IArtwork): string => {
  return `/api/v1/previews/${artwork.pictureId ?? artwork.picture.id}`
}

export const ellipsisText = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  }
  return str.substr(0, maxLength - 3) + "..."
}

export const getSeoDataFromArtwork = (artwork: IArtwork): SeoProps => {
  return {
    title: artwork.name,
    description: artwork.description,
    image: getArtworkPreviewUrl(artwork),
  }
}
