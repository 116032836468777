import React, { useEffect, useState } from "react"
import MainMenu from "./main-menu"
import UserMenu from "./user-menu"
import Image from "next/image"
import Link from "next/link"

import logo from "../../../public/assets/images/arezzo-media-logo.png"
import logoBlack from "../../../public/assets/images/arezzo-media-logo-black.png"
import { mergeClasses } from "../../utils"
import MobileMenu from "./mobile-menu"

export interface TopBarProps {
  transparent?: boolean
}

const TopBar: React.FC<TopBarProps> = ({ transparent = false }) => {
  const [isSticky, setIsSticky] = useState(false)

  const containerClasses: string[] = [
    "flex justify-between h-[67px] px-[25px] py-[16px]",
  ]
  if (transparent) {
    containerClasses.push("bg-transparent")
  } else {
    containerClasses.push("bg-white border-b border-slate-200")
  }

  const onWindowScroll = () => {
    if (window.scrollY >= 100) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }

  useEffect(() => {
    onWindowScroll()
    window.addEventListener("scroll", onWindowScroll)

    return () => {
      window.removeEventListener("scroll", onWindowScroll)
    }
  }, [])

  return (
    <div
      className={mergeClasses([
        "fixed w-screen backdrop-blur-sm top-0 left-0 right-0 z-10",
        isSticky ? "bg-arezzo z-10" : "",
      ])}
    >
      <div className="hidden lg:block">
        <div className={mergeClasses(containerClasses)}>
          <div className="flex space-x-2 align-middle">
            <Link href="/">
              <div
                className="relative cursor-pointer"
                style={{ width: 150, height: 35 }}
              >
                <Image
                  src={transparent ? logo : logoBlack}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </Link>
            <MainMenu transparent={transparent} />
          </div>
          <UserMenu transparent={transparent} />
        </div>
      </div>
      <div className="lg:hidden border-b bg-white">
        <MobileMenu />
      </div>
    </div>
  )
}

export default TopBar
