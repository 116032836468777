import { ITag } from "../../interfaces/models"
import React from "react"

export interface TagProps {
  data: ITag
  active?: boolean
  onClick?: (tag: ITag) => void
}

const Tag: React.FC<TagProps> = ({ data, active, onClick }) => {
  return (
    <div
      className={`
          cursor-pointer
          rounded-full
          px-4 py-1 mb-2 mr-2
          text-white
          whitespace-nowrap
          ${active ? "bg-arezzo" : "bg-gray-800"}`}
      onClick={() => {
        onClick && onClick(data)
      }}
    >
      {data.name}
    </div>
  )
}

export default Tag
