import Link from "next/link"
import React from "react"
import { useTranslation } from "react-i18next"

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-arezzo p-8 text-center text-white space-y-4">
      <div className="space-x-4">
        <Link href="/privacy">{t("button:privacyPolicy")}</Link>
        <Link href="/tos">{t("button:tos")}</Link>
        <Link href="/cookie">{t("button:cookiePolicy")}</Link>
      </div>
      <hr className="w-64 m-auto" />
      <p>
        arezzomedia è di proprietà della fondazione Arezzo Intour - all right
        reserved - info@arezzointour.it
        <br />
        In collaborazione con Regione Toscana, Toscana Promozione Turistica e
        Fondazione Sistema Toscana
      </p>
    </div>
  )
}

export default Footer
