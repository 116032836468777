import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useUser } from "@auth0/nextjs-auth0"
import Link from "next/link"
import { CollectionIcon, SearchIcon } from "@heroicons/react/outline"
import { useTranslation } from "react-i18next"
import { Menu, Transition } from "@headlessui/react"
import { getUserAvatar, mergeClasses } from "../../utils"
import { ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import { Button } from "../button"
import Spinner from "../feedback/spinner"
import useSWR from "swr"
import axios from "axios"
import { IUserProfile } from "../../interfaces/models"
import { SignupModal } from "../feedback"

export interface UserMenuProps {
  transparent?: boolean
}

const iconClasses = "h-5 w-5"

const UserMenu: React.FC<UserMenuProps> = ({ transparent = false }) => {
  const { user, isLoading, error } = useUser()
  const { t } = useTranslation()
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null)
  const [showSignupModal, setShowSignupModal] = useState(false)

  const { data, isValidating } = useSWR(`/api/v1/account/profile`, url =>
    axios.get(url).then(res => res.data)
  )

  useEffect(() => {
    if (data?.profile) {
      setUserProfile(data?.profile)
    }
  }, [data])

  const [userDropdownMenuItems] = useState([
    { label: t("menu:myProfile"), url: "/account/profile" },
    { label: t("menu:changePassword"), url: "/account/change-password" },
    { label: t("menu:myFavorites"), url: "/account/favorites" },
    { label: t("menu:myCollections"), url: "/account/collections" },
    { label: t("menu:myDownloads"), url: "/account/downloads" },
    { label: t("menu:myPurchases"), url: "/account/purchases" },
    { label: t("menu:logout"), url: "/api/auth/logout" }
  ])

  const menuItemClasses = ["font-bold cursor-pointer"]
  if (transparent) {
    menuItemClasses.push("text-white")
  }

  const userMenuItem = useMemo(() => {
    if (isLoading) {
      return (
        <li className={mergeClasses(menuItemClasses)}>
          <Spinner />
        </li>
      )
    }

    if (error) {
      return (
        <li className={mergeClasses(menuItemClasses)}>
          <Button ghost className="bg-indigo-500 ..." disabled>
            <span className={"animate-ping h-5 w-5 mr-3"}>
              <ExclamationCircleIcon />
            </span>
            <span>{t("error:userLoading")}</span>
          </Button>
        </li>
      )
    }

    if (!user) {
      return (
        <li className={mergeClasses(menuItemClasses)}>
          <div
            className={
              "font-bold cursor-pointer bg-arezzo text-white px-4 py-2 rounded flex items-center space-x-2 hover:bg-opacity-40"
            }
            onClick={() => setShowSignupModal(!showSignupModal)}
          >
            {t("button:accessNow")}
            {(showSignupModal) && <SignupModal />}
          </div>
        </li>
      )
    }

    return (
      <>
        <li className={mergeClasses(menuItemClasses)}>
          <Link href="/account/collections">
            <div>
              <CollectionIcon className={iconClasses} />
            </div>
          </Link>
        </li>
        {/*<li className={mergeClasses(menuItemClasses)}>*/}
        {/*  <Link href="/notifications">*/}
        {/*    <div>*/}
        {/*      <BellIcon className={iconClasses} />*/}
        {/*    </div>*/}
        {/*  </Link>*/}
        {/*</li>*/}
        <li className={mergeClasses(menuItemClasses)}>
          <Menu
            as="div"
            className="relative inline-block text-left drop-shadow-xl z-20"
          >
            <div>
              <Menu.Button
                className="inline-flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-white bg-arezzo rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <div className={`rounded-full overflow-hidden`}>
                  <img
                    className="w-8"
                    src={getUserAvatar(user, "initials")}
                    alt="user icon"
                  />
                </div>
                <span className={"font-bold text-white ml-2"}>
                  {userProfile
                    ? `${userProfile.firstname} ${userProfile.lastname}`
                    : user.email}
                </span>
                <ChevronDownIcon className={iconClasses} />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="
              absolute z-20 right-0 w-56 mt-2 origin-top-right
              rounded-md
              bg-white
              divide-y divide-gray-100
              shadow-lg
              ring-1 ring-black ring-opacity-5
              focus:outline-none
              "
              >
                {userDropdownMenuItems.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-arezzo/30 text-black" : "text-gray-900"
                        } px-4 group flex items-center w-full  text-sm`}
                      >
                        <Link href={item.url}>
                          <span className="w-full text-left px-2 py-2">
                            {item.label}
                          </span>
                        </Link>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </li>
      </>
    )
  }, [user, isLoading, error, menuItemClasses])

  return (
    <ul className="flex items-center space-x-4">
      <li className={mergeClasses(menuItemClasses)}>
        <Link href="/search">
          <div>
            <SearchIcon className={iconClasses} />
          </div>
        </Link>
      </li>
      {userMenuItem}
    </ul>
  )
}

export default UserMenu
