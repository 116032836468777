import React from "react"
import { mergeClasses } from "../../utils"

export type ButtonTypes = "normal" | "primary" | "success" | "danger"

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: ButtonTypes
  ghost?: boolean
  block?: boolean
  active?: boolean
  disabled?: boolean
  htmlType?: "button" | "submit" | "reset"
  form?: string
}

const buttonBgColors = {
  normal: "bg-neutral-500",
  primary: "bg-arezzo",
  success: "bg-lime-500",
  danger: "bg-red-500",
}

const buttonBorderColors = {
  normal: "border-neutral-500",
  primary: "border-arezzo",
  success: "border-lime-500",
  danger: "border-red-500",
}

const ghostTextColors = {
  normal: "text-white",
  primary: "text-arezzo",
  success: "text-lime-500",
  danger: "text-red-500",
}

const ghostBorderColor = {
  ...buttonBorderColors,
  normal: "border-white",
}

const Button: React.FC<ButtonProps> = ({
  children,
  active,
  ghost = false,
  disabled,
  type = "normal",
  htmlType = "button",
  block,
  ...rest
}) => {
  const containerClasses = [
    "px-3 py-1 rounded-md",
    "items-center text-base",
    "cursor-pointer",
    "font-medium",
    "focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
  ]

  if (block) {
    containerClasses.push("w-full justify-center")
  }

  if (ghost) {
    containerClasses.push(
      `bg-transparent border-2 border-solid ${ghostBorderColor[type]} ${ghostTextColors[type]}`
    )
    containerClasses.push(`hover:opacity-75`)
  } else {
    containerClasses.push(`${buttonBgColors[type]} text-white`)
    containerClasses.push(`hover:bg-opacity-75`)
  }

  if (active) {
    containerClasses.push("bg-opacity-75")
  }

  if (disabled) {
    containerClasses.push("disabled")
  }

  return (
    <button
      {...rest}
      type={htmlType}
      className={mergeClasses(containerClasses)}
    >
      {children}
    </button>
  )
}

export default Button
