import { Tab } from "@headlessui/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { mergeClasses } from "../../utils"

export interface AccountTopMenuProps {}

const AccountTopMenu: React.FC<AccountTopMenuProps> = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const [menuItems] = useState([
    { label: t("menu:myCollections"), url: "/account/collections" },
    { label: t("menu:myDownloads"), url: "/account/downloads" },
    { label: t("menu:myPurchases"), url: "/account/purchases" },
  ])

  return (
    <div className={"sm:px-10 px-2"}>
      <Tab.Group
        defaultIndex={menuItems.findIndex(item => item.url === router.pathname)}
      >
        <Tab.List className="flex justify-left p-1 space-x-2 ">
          {({ selectedIndex }) => {
            return menuItems.map((item, index) => (
              <Tab key={index}>
                <Link href={item.url}>
                  <button
                    className={mergeClasses([
                      "px-2.5 py-2.5 text-sm leading-5 font-medium rounded-lg",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 text-gray-500",
                      selectedIndex === index
                        ? "bg-arezzo shadow text-white"
                        : "text-gray-500 hover:bg-arezzo hover:text-white",
                    ])}
                  >
                    {item.label}
                  </button>
                </Link>
              </Tab>
            ))
          }}
        </Tab.List>
      </Tab.Group>
    </div>
  )
}

export default AccountTopMenu
