import React, { Fragment } from "react"
import useSWR from "swr"
import axios from "axios"
import { getQueryParamAsString, mergeClasses } from "../../utils"
import { useRouter } from "next/router"
import { ITaxonomy } from "../../interfaces/models"
import { Spinner } from "../feedback"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"

export interface TaxonomyPickerProps {
  onChange?: (taxonomy: string) => void
}

const BUTTON_CLASSES = ["rounded-full px-4 py-2 cursor-pointer"]

const TaxonomyPicker: React.FC<TaxonomyPickerProps> = ({ onChange }) => {
  const router = useRouter()

  const { data, error, isValidating } = useSWR(`/api/v1/taxonomies`, url =>
    axios.get(url).then(res => res.data)
  )

  const currentTaxonomyName =
    getQueryParamAsString(router.query.taxonomy) ?? "photo"

  const currentTaxonomy = data?.taxonomies.find(
    (t: ITaxonomy) => t.name === currentTaxonomyName
  )

  if (isValidating) {
    return <Spinner />
  }

  return (
    <div>
      <div className="hidden lg:block">
        <div className="flex">
          {data &&
            data.taxonomies.map((taxonomy: ITaxonomy, index: number) => {
              const isActive = taxonomy.name === currentTaxonomyName

              const buttonClasses = [...BUTTON_CLASSES]

              if (isActive) {
                buttonClasses.push("bg-arezzo text-white")
              } else {
                buttonClasses.push(
                  "bg-transparent text-black hover:text-arezzo"
                )
              }

              return (
                <div
                  key={index}
                  className={mergeClasses(buttonClasses)}
                  onClick={() => {
                    onChange && onChange(taxonomy.name)
                  }}
                >
                  {taxonomy.label}
                </div>
              )
            })}
        </div>
      </div>
      <div className="lg:hidden">
        <Listbox
          value={currentTaxonomyName}
          onChange={value => {
            onChange && onChange(value)
          }}
        >
          <div className="relative mt-1">
            <Listbox.Button className="relative rounded w-full cursor-default py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">{currentTaxonomy?.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 mt-1 sm:max-h-60 w-42 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
                {data &&
                  data.taxonomies.map((taxonomy: ITaxonomy, index: number) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={taxonomy.name}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {taxonomy.label}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  )
}

export default TaxonomyPicker
