import React from "react"
import AccountTopMenu from "./account-top-menu"
import { useUser } from "@auth0/nextjs-auth0"
import { Button } from "../button"
import { useTranslation } from "react-i18next"
import { PencilAltIcon } from "@heroicons/react/solid"
import Link from "next/link"
import { getDisplayName, getUserAvatar } from "../../utils"
import useProfile from "../../hooks/use-profile"

export interface AccountSettingWrapperProps {}

const AccountSettingWrapper: React.FC<AccountSettingWrapperProps> = ({
  children,
}) => {
  const { user } = useUser()
  const { profile } = useProfile()
  const { t } = useTranslation()
  return (
    <div className={"container lg:flex mx-auto min-h-full px-6"}>
      <div className="h-full lg:flex-none bg-[#F3ECEA] mb-6 min-h-96 rounded-lg space-y-4 text-center p-10">
        <div className={"w-full flex justify-center "}>
          <img
            className={
              "w-32 h-32 rounded-full border border-arezzo overflow-hidden"
            }
            src={getUserAvatar(user, "initials")}
            alt="user icon"
          />
        </div>
        <div className={"space-y-4 flex-col"}>
          <h1 className={"font-semibold text-xl text-[#8A8A8A]"}>
            {getDisplayName(profile)}
          </h1>
          <div>
            {user?.email && (
              <span className={"text-md text-[#393939]"}>{user?.email}</span>
            )}
          </div>
          <div>
            <Link href={"/account/profile"}>
              <Button type={"primary"}>
                <div className="flex justify-center items-center">
                  <span>{t("button:manageYourAccount")}</span>
                  <PencilAltIcon className={"w-5 h-5 ml-2"} />
                </div>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:flex-1">
        <AccountTopMenu />
        <div className={"sm:p-10 p-2"}>{children}</div>
      </div>
    </div>
  )
}

export default AccountSettingWrapper
