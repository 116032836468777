import React, { Fragment, ReactElement } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { mergeClasses } from "../../utils"
import { ExclamationCircleIcon } from "@heroicons/react/outline"

export type ModalSize = "small" | "medium" | "large"

export interface ModalProps {
  title?: string | ReactElement
  footer?: string | ReactElement
  visible?: boolean
  onClose?: () => void
  closable?: boolean
  size?: ModalSize
  icon?: ReactElement
  rounded?: boolean
}

const sizeClasses = {
  small: "min-w-[200px] max-w-[600px] w-1/5",
  medium: "min-w-[300px] max-w-[600px] w-1/5 sm:min-w-[400px] sm:max-w-[800px] sm:w-3/5",
  large: "lg:min-w-[600px] max-w-full lg:w-4/5",
}

const Modal: React.FC<ModalProps> = ({
  visible = true,
  onClose,
  children,
  title,
  footer,
  closable = true,
  size = "medium",
  icon = <ExclamationCircleIcon />,
  rounded = false,
}) => {
  const sizeClass = sizeClasses[size]

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto"
        onClose={() => {
          closable && onClose && onClose()
        }}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75">
              {closable && (
                <div
                  className="fixed text-xs top-1 right-1 cursor-pointer"
                  onClick={() => {
                    onClose && onClose()
                  }}
                >
                  <XIcon className="w-10 h-10 text-white hover:text-slate-50" />
                </div>
              )}
            </Dialog.Overlay>
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={mergeClasses([
                "inline-block my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl",
                sizeClass,
                rounded ? "rounded-xl" : "",
              ])}
            >
              {title && (
                <div className="bg-[#F3ECEA] flex space-x-2 p-2 items-center">
                  <div className={"text-arezzo w-4 h-4"}>{icon}</div>
                  {title}
                </div>
              )}
              <div>{children}</div>
              {footer && <div className="p-2 border border-t">{footer}</div>}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
