import React from "react"
import SearchInput from "./search-input"
import TaxonomyPicker from "./taxonomy-picker"

export interface SearchBarProps {
  onSearch?: (search: string) => void
  onTaxonomyChange?: (taxonomy: string) => void
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  onTaxonomyChange,
}) => {
  return (
    <div className="flex space-x-2 items-center border border-gray-300 rounded-full pl-4 bg-white text-black bg-gray-200">
      <div className="flex-1">
        <SearchInput onSearch={onSearch} />
      </div>
      <div className="flex-none border-l lg:px-4 py-2 bg-white rounded-r-full">
        <TaxonomyPicker onChange={onTaxonomyChange} />
      </div>
    </div>
  )
}

export default SearchBar
