import React, { useEffect, useState } from "react"
import { Modal } from "../modal"
import { Button } from "../button"
import { useUser } from "@auth0/nextjs-auth0"
import Link from "next/link"
import logo from "../../../public/og-image.png"
import Image from "next/image"
import { Popover, Transition } from "@headlessui/react"

export interface SignupModalProps {
}


const SignupModal: React.FC<SignupModalProps> = () => {
  const [showSignupModal, setShowSignupModal] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)

  const { isLoading, user, error } = useUser()

  useEffect(() => {
    if (isLoading || Boolean(error)) {
      return
    }

    if (user) {
      setShowSignupModal(false)
    } else {
      setShowSignupModal(true)
    }
  }, [isLoading, user, error])

  const onCheckboxClick = () => {
    if (agreeTerms) {
      setAgreeTerms(false)
    } else setAgreeTerms(true)
  }

  return (
    <Modal
      visible={showSignupModal}
      footer={
        <div className="text-center">
          <div className="space-x-4">
            <Link href="/api/auth/login">
              <Button type="primary">Accedi</Button>
            </Link>
            <Button onClick={() => {
              setShowTermsModal(true)
              setAgreeTerms(false)
            }} type="primary">Registrati ora</Button>
            <Popover className="terms-popover">
              <Transition
                show={showTermsModal}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Popover.Panel className="absolute z-10 h-full left-0 right-0 inset-y-1 p-6 bg-white">
                  <h2 className="f-s-">Termini e Condizioni</h2>
                  <br />
                  Il presente documento riporta <Link href="/tos"><p className={"text-arezzo"}>i Termini e le
                  Condizioni </p></Link>
                  generali sulla base dei
                  quali viene offerto agli utenti l&apos;uso del sito web www.arezzomedia.com
                  <br />
                  <br />
                  <input type="checkbox" onClick={onCheckboxClick} />
                  <span className="ml-2">
                                        Accetto i termini e le condizioni
                                        della privacy
                                        </span>
                  <div className="flex mt-4 items-center justify-center space-x-4">
                    <Button ghost onClick={() => {
                      setShowTermsModal(false)
                    }} type="primary">Chiudi</Button>
                    {(agreeTerms) &&
                      <Link href="/api/v1/signup">
                        <Button type="primary">Registrati</Button>
                      </Link>
                    }
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        </div>
      }
      onClose={() => {
        setShowSignupModal(false)
      }}
    >
      <div className="text-center p-8">
        <div
          className="relative w-64 m-auto"
          style={{
            aspectRatio: "4.8"
          }}
        >
          <Image src={logo.src} layout="fill" />
        </div>
      </div>
      <p className="p-4 text-center">
        Benvenuto nel nuovo portale Arezzo Media. Per usufruire dei servizi
        offerti è necessario registrarsi
        <br />
        (Clicca su registrati in caso tu non abbia già un account).
      </p>
    </Modal>
  )
}

export default SignupModal
