import React, { Fragment } from "react"
import Color from "color"
import {
  COLOR_DEGREE_STAIR_VALUE,
  COLOR_GRAY_STAIR_VALUE,
  COLOR_LUMINOSITY_STAIR_VALUE,
  COLOR_SATURATION_STAIR_VALUE,
} from "../../constants/color-settings"
import { Popover, Transition } from "@headlessui/react"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { getQueryParamAsString, mergeClasses } from "../../utils"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { ColorSwatchIcon } from "@heroicons/react/outline"

const colorMatrix: Color[][] = []

const initialDegree = 180
const grayScales: Color[] = []
for (let i = 0; i < 11; i++) {
  grayScales.push(Color().hsv(0, 0, i * COLOR_GRAY_STAIR_VALUE))
}
grayScales.push(Color().hsv(0, 0, 100))

for (let i = 0; i < 10; i++) {
  colorMatrix[i] = colorMatrix[i] || []
  for (let k = 0; k < 12; k++) {
    colorMatrix[i][k] = colorMatrix[i][k] || []

    colorMatrix[i][k] = Color().hsv(
      COLOR_DEGREE_STAIR_VALUE * k + initialDegree,
      Math.min(90, (i + 1) * COLOR_SATURATION_STAIR_VALUE),
      90 - Math.max(i - 5, 0) * COLOR_LUMINOSITY_STAIR_VALUE
    )
    // colorMatrix[i][k] = Color().ansi16((i + 1) * k)
  }
}

colorMatrix[11] = grayScales

interface ColorBoxProps {
  color: Color
  active?: boolean
  onClick?: (color: Color) => void
}

const ColorBox: React.FC<ColorBoxProps> = ({ color, active, onClick }) => {
  const classes = [
    "hover:outline hover:outline-white hover:z-10 cursor-pointer w-8 h-5 inline-flex items-center text-base",
  ]
  if (active) {
    classes.push("outline outline-white z-10")
  }

  return (
    <div
      className={mergeClasses(classes)}
      onClick={() => {
        onClick && onClick(color)
      }}
      style={{
        backgroundColor: color.hex(),
      }}
    >
      {active && <CheckCircleIcon className="h-4 text-white m-auto" />}
    </div>
  )
}

export interface PalettePickerProps {
  onChange?: (color: Color | null) => void
}

const PalettePicker: React.FC<PalettePickerProps> = ({ onChange }) => {
  const router = useRouter()
  const { t } = useTranslation()

  const colorParam = getQueryParamAsString(router.query.color)

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="w-full">
            <div
              className={`flex space-x-2 border ${
                open ? "border-arezzo" : "border-slate-200"
              } rounded px-4 py-2 min-w-[200px] text-left hover:border-slate-500`}
            >
              <ColorSwatchIcon className="w-4" />
              {colorParam && (
                <div className="flex space-x-2 align-middle">
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                      backgroundColor: `#${colorParam}`,
                    }}
                  />
                  <div>{`#${colorParam}`.toUpperCase()}</div>
                </div>
              )}
              <div
                className={`transition-all ${
                  colorParam
                    ? "absolute -translate-y-6 -translate-x-7 bg-white scale-75 px-2"
                    : ""
                }`}
              >
                <div className="flex">{t("button:selectColor")}</div>
              </div>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 max-w-[-webkit-fill-available]">
              {({ close }) => (
                <div className="p-4 bg-white shadow-md rounded border">
                  {colorMatrix.map((row, index) => (
                    <div key={index} className="color-row flex">
                      {row.map((color, index) => (
                        <ColorBox
                          key={index}
                          color={color}
                          active={
                            color.hex() === `#${colorParam}`.toUpperCase()
                          }
                          onClick={color => {
                            let _color: Color | null = color
                            if (
                              color.hex() === `#${colorParam}`.toUpperCase()
                            ) {
                              _color = null
                            }
                            onChange && onChange(_color)
                            close()
                          }}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default PalettePicker
