import React, { useMemo, useState } from "react"
import { MenuIcon, SearchIcon, XIcon } from "@heroicons/react/outline"
import Link from "next/link"
import Image from "next/image"
import logo from "../../../public/assets/images/arezzo-media-logo-black.png"
import { getDisplayName, getUserAvatar, mergeClasses } from "../../utils"
import { useTranslation } from "react-i18next"
import { ErrorView, Spinner } from "../feedback"
import useProfile from "../../hooks/use-profile"
import { useUser } from "@auth0/nextjs-auth0"

export interface MobileUserMenuProps {}

const menuItemClasses = ["font-bold hover:underline"]

const iconClasses = "h-5 w-5"

const MobileUserMenu: React.FC<MobileUserMenuProps> = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { profile, loading, error } = useProfile()

  const [menuItems] = useState([
    { label: t("menu:myCollections"), url: "/account/collections" },
    { label: t("menu:myDownloads"), url: "/account/downloads" },
    { label: t("menu:myPurchases"), url: "/account/purchases" },
    { label: t("menu:changePassword"), url: "/account/change-password" },
    { label: t("menu:myFavorites"), url: "/account/favorites" },
  ])

  const menu = useMemo(() => {
    if (!user) {
      return (
        <>
          <li className={mergeClasses(menuItemClasses)}>
            <Link href="/api/auth/login">{t("button:accessNow")}</Link>
          </li>
        </>
      )
    }

    return (
      <>
        <li className={mergeClasses(menuItemClasses)}>
          <div className="flex items-center space-x-2">
            <img
              className="w-8 h-8 rounded-full overflow-hidden"
              src={getUserAvatar(profile || user, "initials")}
              alt="user icon"
            />
            <span>{getDisplayName(profile || user)}</span>
          </div>
        </li>
        {menuItems.map((item, index) => (
          <li key={index} className={mergeClasses(menuItemClasses)}>
            <Link href={item.url}>{item.label}</Link>
          </li>
        ))}
        <hr />
        <li className={mergeClasses([...menuItemClasses, "text-red-500"])}>
          <Link href={"/api/auth/logout"}>{t("button:logout")}</Link>
        </li>
      </>
    )
  }, [profile, user])

  if (loading) {
    return <Spinner />
  }

  // if (error) {
  //   return <ErrorView error={error}></ErrorView>
  // }

  return <ul className="flex-col items-center space-y-4">{menu}</ul>
}

export interface MobileMenuProps {}

const MobileMenu: React.FC<MobileMenuProps> = () => {
  const [showMenu, setShowMenu] = useState(false)

  const { t } = useTranslation()

  const menuItems = useMemo(() => {
    return [
      { href: "/", name: t("menu:home") },
      { href: "/search", name: t("menu:search") },
      { href: "/albums", name: t("menu:albums") },
      { href: "/work-with-us", name: t("menu:workWithUs") },
    ]
  }, [])

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <div>
      <div className="flex justify-between items-center px-3">
        <div className="flex space-x-1 items-center">
          <MenuIcon className={iconClasses} onClick={toggleMenu} />
          <Link href="/">
            <div
              className="relative cursor-pointer"
              style={{ width: 100, height: 50 }}
            >
              <Image src={logo} layout="fill" objectFit="contain" />
            </div>
          </Link>
        </div>
        <div>
          <Link href="/search">
            <SearchIcon className={iconClasses} />
          </Link>
        </div>
      </div>
      <div className={showMenu ? "block" : "hidden"}>
        <div
          className="bg-white/50 fixed h-screen top-0 bottom-0 left-0 right-0 z-30"
          onClick={() => {
            setShowMenu(false)
          }}
        >
          <div className="bg-white space-y-2 p-2 drop-shadow-2xl">
            <ul className="flex-col items-center space-y-4">
              <li className={mergeClasses(menuItemClasses)}>
                <XIcon className={iconClasses} />
              </li>
              {menuItems.map(({ href, name }, index: number) => (
                <li key={index} className={mergeClasses(menuItemClasses)}>
                  <Link href={href}>{name}</Link>
                </li>
              ))}
            </ul>
            <hr />
            {<MobileUserMenu />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
