import React from "react"
import { useTranslation } from "react-i18next"
import { SearchBar } from "../artwork-filter"
import Image from "next/image"

import bg from "../../../public/assets/images/home-header-bg@2x.jpg"
import logo from "../../../public/assets/images/logo-text-white.svg"
import { useRouter } from "next/router"
import { FilterOptions } from "../artwork-filter/artwork-filter"

export interface HomePageHeaderProps {}

const HomePageHeader: React.FC<HomePageHeaderProps> = ({}) => {
  const { t } = useTranslation()

  const router = useRouter()

  const handleFilterChange = (options: FilterOptions) => {
    router.push({
      pathname: "/search/[taxonomy]",
      query: { ...options },
    })
  }

  const handleSearch = (search: string) => {
    const newParams = { ...router.query }
    if (search) {
      newParams.search = search
    } else {
      delete newParams.search
    }

    handleFilterChange({ taxonomy: "photo", ...newParams })
  }

  const handleTaxonomyChange = (taxonomy: string) => {
    const newParams = { taxonomy }
    handleFilterChange(newParams)
  }

  return (
    <div
      className="text-center py-[250px] bg-no-repeat bg-center bg-cover relative"
      style={{ backgroundImage: `url(/assets/images/home-header-bg@2x.jpg)` }}
    >
      <Image src={bg} layout="fill" objectFit="cover" objectPosition="bottom" />
      <div className="absolute top-0 right-0 bottom-0 left-0 m-auto max-w-screen-lg h-1/2 m-auto text-white space-y-8 px-6">
        <div className="space-y-2">
          <Image src={logo} />
          <h2 className="text-4xl">{t("label:platformSlogan")}</h2>
        </div>
        <SearchBar
          onSearch={handleSearch}
          onTaxonomyChange={handleTaxonomyChange}
        />
      </div>
    </div>
  )
}

export default HomePageHeader
