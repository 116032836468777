import React, { useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import { getQueryParamAsString } from "../../utils"
import { useTranslation } from "react-i18next"
import { SearchIcon, XIcon } from "@heroicons/react/outline"

export interface SearchInputProps {
  onSearch?: (search: string) => void
}

const SearchInput: React.FC<SearchInputProps> = ({ onSearch }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [searchText, setSearchText] = useState<string | undefined>("")
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setSearchText(getQueryParamAsString(router.query.search) || "")
  }, [router])

  return (
    <div className="flex space-x-2 items-center max-w-[220px] sm:max-w-full">
      <SearchIcon className="flex-none h-6" />
      <input
        className="flex-1 outline-0 bg-transparent h-10 max-w-[150px] sm:max-w-full"
        type="text"
        placeholder={t("label:typeHereToSearch")}
        ref={inputRef}
        value={searchText}
        onChange={evt => setSearchText(evt.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            onSearch && onSearch(inputRef?.current?.value ?? "")
          }
        }}
      />
      {searchText && (
        <XIcon
          className="flex-none h-6 cursor-pointer hover:text-arezzo m-0"
          onClick={() => {
            onSearch && onSearch("")
          }}
        />
      )}
    </div>
  )
}

export default SearchInput
