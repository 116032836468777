import { CustomIconProps } from "."
import React from "react"

const AdjustmentIcon: React.FC<CustomIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      className={className}
    >
      <defs>
        <clipPath id="a">
          <rect
            width="19"
            height="19"
            transform="translate(11 10)"
            fill="#494949"
          />
        </clipPath>
      </defs>
      <g transform="translate(-11 -10)" clipPath="url(#a)">
        <g transform="translate(8.808 7.808)">
          <path
            d="M20.462,5.115H10.231A1.463,1.463,0,0,0,8.769,3.654H7.308A1.463,1.463,0,0,0,5.846,5.115H2.923a.731.731,0,0,0,0,1.462H5.846A1.463,1.463,0,0,0,7.308,8.038H8.769a1.463,1.463,0,0,0,1.462-1.462H20.462a.731.731,0,0,0,0-1.462ZM7.308,6.577V5.115H8.769v.728s0,0,0,0,0,0,0,0v.728Z"
            fill="#494949"
          />
          <path
            d="M20.462,10.962H16.077A1.463,1.463,0,0,0,14.615,9.5H13.154a1.463,1.463,0,0,0-1.462,1.462H2.923a.731.731,0,1,0,0,1.462h8.769a1.463,1.463,0,0,0,1.462,1.462h1.462a1.463,1.463,0,0,0,1.462-1.462h4.385a.731.731,0,0,0,0-1.462Zm-7.308,1.462V10.962h1.462v.728s0,0,0,0,0,0,0,0v.728Z"
            fill="#494949"
          />
          <path
            d="M20.462,16.808H10.231a1.463,1.463,0,0,0-1.462-1.462H7.308a1.463,1.463,0,0,0-1.462,1.462H2.923a.731.731,0,1,0,0,1.462H5.846a1.463,1.463,0,0,0,1.462,1.462H8.769a1.463,1.463,0,0,0,1.462-1.462H20.462a.731.731,0,0,0,0-1.462ZM7.308,18.269V16.808H8.769v.728s0,0,0,0,0,0,0,0v.728Z"
            fill="#494949"
          />
        </g>
      </g>
    </svg>
  )
}

export default AdjustmentIcon
