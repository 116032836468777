import useSWR from "swr"
import axios from "axios"

const useProfile = () => {
  const { data, error, isValidating } = useSWR(`/api/v1/account/profile`, url =>
    axios.get(url).then(res => res.data)
  )

  console.log({ data })

  return {
    loading: isValidating,
    error,
    profile: data?.profile || null,
  }
}

export default useProfile
