import React, { Fragment, useEffect, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { AdjustmentIcon } from "../custom-icons"
import { DEFAULT_ORDER_BY_OPTIONS } from "../../constants/application-settings"

export type OrderByOption = "+name" | "-name" | "+createdAt" | "-createdAt"

export interface OrderByProps {
  onChange?: (orderBy: OrderByOption) => void
}

const OrderBy: React.FC<OrderByProps> = ({ onChange }) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [orderBy, setOrderBy] = useState<OrderByOption | undefined>()

  const [keyTranslations, setKeyTranslations] = useState({
    "+name": t("label:+name"),
    "-name": t("label:-name"),
    "+createdAt": t("label:+createdAt"),
    "-createdAt": t("label:-createdAt"),
  })

  useEffect(() => {
    setOrderBy(router.query.orderBy as OrderByOption)
  }, [router])

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="w-full">
            <div
              className={`flex space-x-2 border ${
                open ? "border-arezzo" : "border-slate-200"
              } rounded px-4 py-2 min-w-[220px] text-left hover:border-slate-500`}
            >
              <AdjustmentIcon className="w-4" />
              {orderBy && (
                <div className="flex space-x-2">
                  <div>{keyTranslations[orderBy]}</div>
                </div>
              )}
              <div
                className={`transition-all ${
                  orderBy
                    ? "absolute -translate-y-6 -translate-x-7 bg-white scale-75 px-2"
                    : ""
                }`}
              >
                <div className="flex">{t("button:orderBy")}</div>
              </div>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10">
              {({ close }) => (
                <div className="p-4 bg-white shadow-md rounded border">
                  {DEFAULT_ORDER_BY_OPTIONS.map((filter, index) => (
                    <div
                      key={index}
                      className="rounded hover:bg-arezzo hover:text-white p-2 cursor-pointer"
                      onClick={event => {
                        event.preventDefault()
                        setOrderBy(filter)
                        onChange && onChange(filter)
                        close()
                      }}
                    >
                      {keyTranslations[filter]}
                    </div>
                  ))}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default OrderBy
