import React from "react"
import CategoryPicker from "./category-picker"
import PalettePicker from "./palette-picker"
import Color from "color"
import { useRouter } from "next/router"
import TagPicker from "./tag-picker"
import OrderBy, { OrderByOption } from "./order-by"
import { SearchBar } from "./index"

export interface FilterOptions {
  color?: string
  taxonomy?: string
  search?: string
  category?: string
  orderBy?: string
  tags?: string[]
}

export interface ArtworkFilterProps {
  onFilterChange?: (options: FilterOptions) => void
}

const ArtworkFilter: React.FC<ArtworkFilterProps> = ({ onFilterChange }) => {
  const router = useRouter()

  const handleCategoryChange = (categoryId: string | undefined | null) => {
    const newParams = { ...router.query }
    if (categoryId) {
      newParams.category = categoryId
    } else {
      delete newParams.category
    }

    onFilterChange && onFilterChange(newParams)
  }

  const handleOrderChange = (orderBy: OrderByOption) => {
    const newParams = { ...router.query }
    if (orderBy) {
      newParams.orderBy = orderBy
    } else {
      delete newParams.orderBy
    }

    onFilterChange && onFilterChange(newParams)
  }

  const handleTagChange = (tagIds: string[]) => {
    onFilterChange && onFilterChange({ ...router.query, tags: tagIds })
  }

  const handleColorChange = (color: Color | null) => {
    const newParams = { ...router.query }
    if (color) {
      newParams.color = color.hex().replace("#", "")
    } else {
      delete newParams.color
    }

    onFilterChange && onFilterChange(newParams)
  }

  const handleSearch = (search: string) => {
    const newParams = { ...router.query }
    if (search) {
      newParams.search = search
    } else {
      delete newParams.search
    }

    onFilterChange && onFilterChange({ taxonomy: "photo", ...newParams })
  }

  const handleTaxonomyChange = (taxonomy: string) => {
    const newParams = { ...router.query }

    delete newParams.tags

    onFilterChange && onFilterChange({ ...newParams, taxonomy })
  }

  return (
    <div className="space-y-8">
      <SearchBar
        onSearch={handleSearch}
        onTaxonomyChange={handleTaxonomyChange}
      />
      <div className="lg:flex justify-between">
        <div className="lg:flex lg:space-x-2">
          <div className="mb-4">
            <CategoryPicker onChange={handleCategoryChange} />
          </div>
          <div className="mb-4">
            <PalettePicker onChange={handleColorChange} />
          </div>
        </div>
        <div>
          <div className="mb-4">
            <OrderBy onChange={handleOrderChange} />
          </div>
        </div>
      </div>
      <TagPicker onChange={handleTagChange} />
    </div>
  )
}

export default ArtworkFilter
