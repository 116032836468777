import React, { MouseEventHandler } from "react"
import { useUserFavorites } from "../../hooks/use-user-favorites"
import { HeartIcon as HeartIconSolid } from "@heroicons/react/solid"
import { HeartIcon as HeartIconOutline } from "@heroicons/react/outline"
import Alert from "../feedback/alert"
import { useUser } from "@auth0/nextjs-auth0"
import { useRouter } from "next/router"
import Spinner from "../feedback/spinner"

export interface LikeButtonProps {
  artworkId: string
}

const LikeButton: React.FC<LikeButtonProps> = ({ artworkId }) => {
  const { favorites, loading, error, like, unlike } = useUserFavorites()
  const { user } = useUser()
  const router = useRouter()

  if (loading) {
    return (
      <div className="flex justify-center items-center h-100">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return <Alert title={null} message={error.message} compact />
  }

  const itemLiked = favorites.find(fav => fav.itemId === artworkId)

  const handleToggleLike: MouseEventHandler = evt => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!user) {
      router.push(`/api/auth/login?returnTo=${router.asPath}`)
      return
    }

    itemLiked ? unlike(artworkId) : like(artworkId)
  }

  return (
    <div
      onClick={handleToggleLike}
      className={`p-2 rounded rounded-full w-8 cursor-pointer ${
        itemLiked ? "bg-arezzo text-white" : "bg-white text-black"
      }`}
    >
      {itemLiked ? <HeartIconSolid /> : <HeartIconOutline />}
    </div>
  )
}

export default LikeButton
