import React from "react"
import { devLog } from "../../utils"
import Error from "next/error"

interface ErrorViewProps {
  error: any
}

const ErrorView: React.FC<ErrorViewProps> = ({ error }) => {
  devLog({ error })
  return <Error statusCode={error.statusCode} title={error.message} />
}

export default ErrorView
