import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Link from "next/link"
import { mergeClasses } from "../../utils"

export interface MainMenuProps {
  transparent?: boolean
}

const MainMenu: React.FC<MainMenuProps> = ({ transparent = false }) => {
  const { t } = useTranslation()

  const menuItems = useMemo(() => {
    return [
      { href: "/", name: t("menu:home") },
      { href: "/search", name: t("menu:search") },
      { href: "/albums", name: t("menu:albums") },
      { href: "/work-with-us", name: t("menu:workWithUs") },
    ]
  }, [])

  const menuItemClasses = ["font-bold hover:underline"]
  if (transparent) {
    menuItemClasses.push("text-white")
  }

  return (
    <ul className="flex items-center space-x-4">
      {menuItems.map(({ href, name }, index: number) => (
        <li key={index} className={mergeClasses(menuItemClasses)}>
          <Link href={href}>{name}</Link>
        </li>
      ))}
    </ul>
  )
}

export default MainMenu
