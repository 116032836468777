import dynamic from "next/dynamic"

const GdprConsent = dynamic(
  // @ts-ignore
  () => import("./gdpr-consent").then(module => module.default),
  {
    ssr: false,
  }
)

// import GdprConsent from "./gdpr-consent"

export { GdprConsent }
