import React, { Fragment } from "react"
import { ExclamationIcon } from "@heroicons/react/outline"
import { Popover, Transition } from "@headlessui/react"

export interface AlertProps {
  title?: string | null
  message?: string | null
  compact?: boolean
}

const Alert: React.FC<AlertProps> = ({ title, message, compact }) => {
  if (compact) {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                text-white group px-2 py-2 bg-warning rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <ExclamationIcon className="h-4" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 p-2 mt-3 transform bg-warning/75  border border-warning rounded">
                {title && <h1>{title}</h1>}
                {message && <p>{message}</p>}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    )
  }

  return (
    <div className="flex space-x-2 align-middle p-2 border border-warning rounded bg-warning/75">
      <ExclamationIcon className="flex-none w-8 m-auto" />
      <div className="flex-auto">
        {title && <h1 className="text-lg">{title}</h1>}
        {message && <p className="">{message}</p>}
      </div>
    </div>
  )
}

export default Alert
