import React from "react"

export interface SpinnerProps {
  spinning?: boolean
  dark?: boolean
}

const Spinner: React.FC<SpinnerProps> = ({
  children,
  spinning = true,
  dark = false,
}) => {
  return (
    <div className="relative inline-block min-h-[35px] min-w-[35px] w-full h-full">
      {children}
      {spinning && (
        <div
          className={`absolute top-0 bottom-0 left-0 right-0 bg-opacity-80 ${
            dark ? "bg-black" : "bg-white"
          }`}
        >
          <div className="flex h-full justify-center items-center">
            <svg className={`h-5 w-5 animate-spin`} viewBox="0 0 50 50">
              <circle
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke={dark ? "white" : "black"}
                strokeWidth="5"
                strokeDasharray="90, 150"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  )
}

export default Spinner
