import React from "react"
import { useRouter } from "next/router"
import useSWR from "swr"
import axios from "axios"
import { getQueryParamAsArray } from "../../utils"
import { ITag } from "../../interfaces/models"
import { Tag } from "../tag"

export interface TagPickerProps {
  onChange?: (tags: string[]) => void
}

const TagPicker: React.ComponentType<TagPickerProps> = ({ onChange }) => {
  const router = useRouter()
  const taxonomy = router.query.taxonomy

  const { data, error, isValidating } = useSWR(
    `/api/v1/tags/${taxonomy}`,
    url => axios.get(url).then(res => res.data)
  )

  const tagIds = getQueryParamAsArray(router.query.tags)

  return (
    <div className="flex flex-wrap text-left">
      {data?.tags.map((tag: ITag, index: number) => (
        <Tag
          key={index}
          data={tag}
          active={tagIds.includes(tag.id)}
          onClick={() => {
            if (tagIds.includes(tag.id)) {
              onChange && onChange(tagIds.filter(item => item !== tag.id))
            } else {
              onChange && onChange([...tagIds, tag.id])
            }
          }}
        />
      ))}
    </div>
  )
}

export default TagPicker
