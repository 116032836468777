import { default as breadcrumb } from "./it_IT/breadcrumb.json"
import { default as button } from "./it_IT/button.json"
import { default as error } from "./it_IT/error.json"
import { default as formError } from "./it_IT/formError.json"
import { default as placeholder } from "./it_IT/placeholder.json"
import { default as label } from "./it_IT/label.json"
import { default as menu } from "./it_IT/menu.json"
import { default as message } from "./it_IT/message.json"
import { default as warning } from "./it_IT/warning.json"
import { default as seo } from "./it_IT/seo.json"
import { default as gdpr } from "./it_IT/gdpr.json"

const it_IT = {
  breadcrumb,
  button,
  error,
  formError,
  placeholder,
  label,
  menu,
  message,
  warning,
  seo,
  gdpr,
}

export default it_IT
