import React, { Fragment, useEffect, useState } from "react"
import useSWR from "swr"
import axios from "axios"
import { getQueryParamAsString } from "../../utils"
import { useRouter } from "next/router"
import { ICategory } from "../../interfaces/models"
import { useTranslation } from "react-i18next"
import { Popover, Transition } from "@headlessui/react"
import { AdjustmentIcon } from "../custom-icons"

const makeCategoryTree = (categories: ICategory[]): ICategory[] => {
  const temp: { [key: string]: ICategory } = {}

  const result: ICategory[] = []

  categories.map(category => {
    temp[category.id] = { ...category, children: [] }
  })

  categories.map(category => {
    if (!category.parentId) {
      result.push(temp[category.id])
    } else {
      temp[category.parentId]?.children?.push(temp[category.id])
    }
  })

  return result
}

interface CategoryTreeProps {
  selectedCategoryId?: string
  data: ICategory[]
  onSelect?: (category: ICategory | null) => void
}

const CategoryTree: React.FC<CategoryTreeProps> = ({
  data,
  selectedCategoryId,
  onSelect,
}) => {
  const { t } = useTranslation()

  const makeTreeNode = (category: ICategory, index: number) => {
    return (
      <div key={category.id}>
        <div
          className={`p-2 rounded cursor-pointer whitespace-nowrap ${
            category.id === selectedCategoryId ? "text-arezzo" : ""
          } hover:bg-arezzo hover:text-white`}
          onClick={() => {
            onSelect && onSelect(category)
          }}
        >
          {category.name}
        </div>
        {category.children && category.children.length > 0 && (
          <div className="ml-2">{category.children.map(makeTreeNode)}</div>
        )}
      </div>
    )
  }

  return (
    <div>
      <div
        className={`p-2 rounded cursor-pointer whitespace-nowrap ${
          selectedCategoryId ? "" : "text-arezzo"
        } hover:bg-arezzo hover:text-white`}
        onClick={() => {
          onSelect && onSelect(null)
        }}
      >
        {t("label:allCategories")}
      </div>
      {data.map(makeTreeNode)}
    </div>
  )
}

export interface CategoryPickerProps {
  onChange?: (categoryId: string | null | undefined) => void
}

const CategoryPicker: React.FC<CategoryPickerProps> = ({ onChange }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { data, error, isValidating } = useSWR(`/api/v1/categories`, url =>
    axios.get(url).then(res => res.data)
  )

  const [categoryTreeData, setCategoryTreeData] = useState<ICategory[]>([])

  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  )

  useEffect(() => {
    const currentCategoryId = getQueryParamAsString(router.query.category)

    if (data?.categories) {
      setCategoryTreeData(makeCategoryTree(data.categories))
      if (currentCategoryId) {
        const category = data.categories.find(
          (item: ICategory) => item.id === currentCategoryId
        )
        setSelectedCategory(category)
      } else {
        setSelectedCategory(null)
      }
    }
  }, [data, router.query])

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="w-full">
            <div
              className={`flex items-center space-x-2 border ${
                open ? "border-arezzo" : "border-slate-200"
              } rounded px-4 py-2 min-w-[200px] text-left hover:border-slate-500`}
            >
              <AdjustmentIcon className="w-4" />
              {selectedCategory && <span>{selectedCategory.name}</span>}
              <div
                className={`transition-all ${
                  selectedCategory
                    ? "absolute -translate-y-6 -translate-x-8 bg-white scale-75 px-2"
                    : ""
                }`}
              >
                <div className="flex">{t("button:selectCategory")}</div>
              </div>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10">
              {({ close }) => (
                <div className="p-4 bg-white shadow-md rounded border">
                  <CategoryTree
                    data={categoryTreeData}
                    selectedCategoryId={selectedCategory?.id}
                    onSelect={category => {
                      setSelectedCategory(category)
                      close()
                      onChange && onChange(category?.id)
                    }}
                  />
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default CategoryPicker
