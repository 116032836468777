import React, { ReactElement } from "react"
import { ModalProps } from "./modal"
import { Modal } from "./index"
import { Button } from "../button"
import { useTranslation } from "react-i18next"

export interface ConfirmModalProps extends ModalProps {
  onCancel?: () => void
  onConfirm?: () => void
  okText?: string
  cancelText?: string
  message: string | ReactElement
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  onConfirm,
  onCancel,
  message,
  okText,
  cancelText,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      {...rest}
      size={"small"}
      closable={false}
      onClose={() => {
        onCancel && onCancel()
      }}
      footer={
        <div className="flex space-x-2 justify-center">
          <Button onClick={onCancel}>{cancelText || t("button:cancel")}</Button>
          <Button type="primary" onClick={onConfirm}>
            {okText || t("button:confirm")}
          </Button>
        </div>
      }
    >
      <div className="p-4">{message}</div>
    </Modal>
  )
}

export default ConfirmModal
