import React from "react"
import Spinner from "./spinner"

export interface LoadingViewProps {}

const LoadingView: React.FC<LoadingViewProps> = () => {
  return (
    <div className="h-[150px] flex justify-center items-center">
      <Spinner />
    </div>
  )
}

export default LoadingView
