import React, { createContext, useContext, useEffect, useState } from "react"
import useSWR from "swr"
import axios from "axios"
import { IFavorite } from "../interfaces/models"
import { uiHandleError } from "../utils"
import { useUser } from "@auth0/nextjs-auth0"

interface UserFavoritesContextProps {
  favorites: IFavorite[]
  loading: boolean
  error: any
  like: (itemId: string) => void
  unlike: (item: string) => void
}

interface UserFavoritesProviderProps {}

const UserFavoritesContext = createContext<UserFavoritesContextProps>({
  favorites: [],
  loading: true,
  error: null,
  like: () => {},
  unlike: () => {},
})

export const UserFavoritesProvider: React.FC<UserFavoritesProviderProps> = ({
  children,
}) => {
  const { user } = useUser()

  const { data, error, isValidating } = useSWR(
    user ? `/api/v1/favorites` : null,
    url => axios.get(url).then(res => res.data)
  )

  const [favorites, setFavorites] = useState<IFavorite[]>([])

  useEffect(() => {
    if (data?.favorites) {
      setFavorites(data?.favorites)
    }
  }, [data])

  const toggleLike = async (itemId: string, like: boolean) => {
    try {
      await axios.post(`/api/v1/artworks/${itemId}/like`, { like })
    } catch (e: any) {
      uiHandleError(e)
    }
  }

  const like = async (itemId: string) => {
    await toggleLike(itemId, true)
    if (user?.sub) {
      setFavorites([...favorites, { itemId, userId: user.sub }])
    }
  }

  const unlike = async (itemId: string) => {
    await toggleLike(itemId, false)
    setFavorites(favorites.filter(fav => fav.itemId !== itemId))
  }

  return (
    <UserFavoritesContext.Provider
      value={{
        like,
        unlike,
        favorites,
        loading: isValidating,
        error: error,
      }}
    >
      {children}
    </UserFavoritesContext.Provider>
  )
}

export const useUserFavorites = () => useContext(UserFavoritesContext)
