import { OrderByOption } from "../components/artwork-filter/order-by"

export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_PREVIEW_IMAGE_HEIGHT = 380
export const ONE_HOUR_IN_SECONDS = 60 * 60
export const ONE_MINUTE_IN_SECONDS = 60

export const STATIC_PAGE_REVALIDATE_INTERVAL = ONE_HOUR_IN_SECONDS

export const DEFAULT_ORDER_BY_OPTIONS: OrderByOption[] = [
  "+name",
  "-name",
  "+createdAt",
  "-createdAt",
]

export const DEFAULT_WALLPAPER_URL = `/assets/images/home-header-bg.jpg`
export const DEFAULT_USER_AVATAR = `/assets/images/avatar.png`
export const DEFAULT_VAT = 0.22

export const DEFAULT_PAGE_LIFE = 3600 //seconds
