import React from "react"
import { useTranslation } from "react-i18next"

export interface EndOfListProps {}

const EndOfList: React.FC<EndOfListProps> = () => {
  const { t } = useTranslation()

  return (
    <div className="text-center text-slate-400 mt-8">
      <div className="inline-block">
        <div className="flex space-x-2">
          <div className="w-16 flex items-center">
            <hr className="w-full" />
          </div>
          <div>{t("label:noMoreItems")}</div>
          <div className="w-16 flex items-center">
            <hr className="w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EndOfList
