import React from "react"
import TopBar from "./top-bar"
import Footer from "./footer"
import { useRouter } from "next/router"
import { GdprConsent } from "../gdpr"

export interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const router = useRouter()

  const transparentPath = ["/"]

  return (
    <>
      <GdprConsent />
      <div className="flex flex-col min-h-screen">
        <TopBar transparent={transparentPath.includes(router.pathname)} />
        <div
          className={
            transparentPath.includes(router.pathname) ? "grow" : "grow mt-20"
          }
        >
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default MainLayout
